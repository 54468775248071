import type { SVGProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import lemonConstants from '~/constants/lemonConstants'

const Facebook: React.FC = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 20.3261H24.9684V17.2792C24.9684 16.3447 25.902 16.1279 26.3377 16.1279C26.7706 16.1279 28.9421 16.1279 28.9421 16.1279V12.0161L25.9572 12C21.8847 12 20.9552 15.0659 20.9552 17.0317V20.3262H18V24.5625H20.9552C20.9552 30.0015 20.9552 36 20.9552 36H24.9684C24.9684 36 24.9684 29.9399 24.9684 24.5625H28.3776L29 20.3261Z"
        fill="currentColor"
      />
    </svg>
  )
}

const Instagram = ({
  className,
  svgProps,
}: {
  className?: string
  svgProps?: SVGProps<SVGSVGElement>
}) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...svgProps}
    >
      <path
        d="M28.5 12H19.5C15.3585 12 12 15.3585 12 19.5V28.5C12 32.6415 15.3585 36 19.5 36H28.5C32.6415 36 36 32.6415 36 28.5V19.5C36 15.3585 32.6415 12 28.5 12ZM33.75 28.5C33.75 31.395 31.395 33.75 28.5 33.75H19.5C16.605 33.75 14.25 31.395 14.25 28.5V19.5C14.25 16.605 16.605 14.25 19.5 14.25H28.5C31.395 14.25 33.75 16.605 33.75 19.5V28.5Z"
        fill="currentColor"
      />
      <path
        d="M24 18C20.6865 18 18 20.6865 18 24C18 27.3135 20.6865 30 24 30C27.3135 30 30 27.3135 30 24C30 20.6865 27.3135 18 24 18ZM24 27.75C21.933 27.75 20.25 26.067 20.25 24C20.25 21.9315 21.933 20.25 24 20.25C26.067 20.25 27.75 21.9315 27.75 24C27.75 26.067 26.067 27.75 24 27.75Z"
        fill="currentColor"
      />
      <path
        d="M30.4499 18.349C30.8914 18.349 31.2494 17.9911 31.2494 17.5495C31.2494 17.1079 30.8914 16.75 30.4499 16.75C30.0083 16.75 29.6504 17.1079 29.6504 17.5495C29.6504 17.9911 30.0083 18.349 30.4499 18.349Z"
        fill="currentColor"
      />
    </svg>
  )
}

const Linkedin: React.FC = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.994 36.0002V35.9992H36V27.1972C36 22.8912 35.073 19.5742 30.039 19.5742C27.619 19.5742 25.995 20.9022 25.332 22.1612H25.262V19.9762H20.489V35.9992H25.459V28.0652C25.459 25.9762 25.855 23.9562 28.442 23.9562C30.991 23.9562 31.029 26.3402 31.029 28.1992V36.0002H35.994Z"
        fill="currentColor"
      />
      <path
        d="M12.396 19.9766H17.372V35.9996H12.396V19.9766Z"
        fill="currentColor"
      />
      <path
        d="M14.882 12C13.291 12 12 13.291 12 14.882C12 16.473 13.291 17.791 14.882 17.791C16.473 17.791 17.764 16.473 17.764 14.882C17.763 13.291 16.472 12 14.882 12V12Z"
        fill="currentColor"
      />
    </svg>
  )
}

interface SocialProps {
  colorScheme?: 'dark' | 'light'
  className?: string
  source?: string
}
const StyledSocial = styled.div<SocialProps>(
  ({ colorScheme = 'light', theme: { spacing, colors } }) => {
    const colorOptions: { [key in 'dark' | 'light']: string } = {
      dark: colors.neutral.darkest,
      light: colors.neutral.lightest,
    }
    return css`
      &,
      a {
        color: ${colorOptions[colorScheme]};
      }
      display: flex;
      gap: ${spacing.sm};
    `
  },
)

const Social: React.FC<SocialProps> = ({ colorScheme, className, source }) => {
  return (
    <StyledSocial colorScheme={colorScheme} className={className}>
      <a
        href={lemonConstants.instagramLemonProfile}
        target="__blank"
        id={`${source ?? 'socials'}-instagram-link`}
      >
        <Instagram />
      </a>
      <a
        href="https://www.linkedin.com/company/lemon-energia/"
        target="__blank"
        id={`${source ?? 'socials'}-linkedin-link`}
      >
        <Linkedin />
      </a>
      <a
        href="https://www.facebook.com/EnergiaLemon"
        target="__blank"
        id={`${source ?? 'socials'}-facebook-link`}
      >
        <Facebook />
      </a>
    </StyledSocial>
  )
}

export default styled(Social)``
